html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: calc(15px + 0.390625vw);
}

* {
  box-sizing: border-box;
}
